import React, { useEffect } from "react"
import { Link, graphql } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

import SEO from "../components/seo"

const Home = ({ data }) => {
  useEffect(() => {
    console.log(
      "%csite by https://ctrotter.co",
      "color:black; font-family: system-ui;"
    )
  }, [])

  const hero = data.prismicHome.data.hero_article.document
  const featuredTitle = data.prismicHome.data.featured_section_title
  const featuredArticles = data.prismicHome.data.featured.map(
    ({ featured_article }) => featured_article.document
  )

  return (
    <>
      <SEO title="Home" />
      <Link to={"/articles/" + hero.uid} className="hero">
        <img className="hero__image" src={hero.data.hero_image.url} />
        <div className="hero__title">
          <p>{hero.data.subtitle}</p>
        </div>
      </Link>
      <h2 className="featured-title">{featuredTitle}</h2>
      <div className="grid grid--featured">
        {featuredArticles.map((article, i) => (
          <Link to={"/articles/" + article.uid} className="grid-item" key={i}>
            <img
              className="grid-item__mobile-image"
              src={article.data.hero_image.url}
            />
            <div className="grid-item__title">
              <p>{article.data.subtitle}</p>
            </div>
          </Link>
        ))}
      </div>
      <h2 className="featured-title">From the archive</h2>
      <div className="grid">
        {data.allPrismicArticle.edges.map((article, i) => (
          <Link
            to={"/articles/" + article.node.uid}
            className="grid-item"
            key={i}
          >
            <img
              className="grid-item__mobile-image"
              src={article.node.data.hero_image.url}
            />
          </Link>
        ))}
      </div>
    </>
  )
}

export const query = graphql`
  query ($ids: [String!]) {
    prismicHome {
      data {
        featured {
          featured_article {
            document {
              ... on PrismicArticle {
                id
                uid
                data {
                  title
                  author
                  hero_image {
                    alt
                    url
                  }
                  subtitle
                }
              }
            }
          }
        }
        featured_section_title
        hero_article {
          document {
            ... on PrismicArticle {
              uid
              id
              data {
                title
                author
                hero_image {
                  alt
                  url
                }
                subtitle
              }
            }
          }
        }
      }
    }

    allPrismicArticle(
      filter: { id: { nin: $ids } }
      sort: { fields: first_publication_date, order: DESC }
    ) {
      edges {
        node {
          id
          data {
            author
            content {
              html
            }
            hero_image {
              alt
              url
            }
            title
            subtitle
          }
          uid
        }
      }
    }
  }
`

export default withPrismicPreview(Home)
